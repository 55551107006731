import React from 'react'
import { Menu, Footer, useLanguage, BaseLayout, withLanguageProvider } from '@tmp'
import { AnimatedText } from '@cmp'
import cn from 'classnames'
import shuffle from 'lodash/shuffle'

const colors = shuffle([
  'text-white bg-violette-400',
  'text-white bg-red-400',
  'text-dark-400 bg-orange-400',
  'text-dark-400 bg-yellow-400',
])

let currentIndex = 0
const getRandomColor = () => colors[currentIndex++ % colors.length]

const color = getRandomColor()

const PageTemplate = () => {
  const { t } = useLanguage()
  return (
    <>
      <BaseLayout
        defaultSeoOverwrites={{
          title: t('not-found', 'Síða fannst ekki', 'Page not found'),
          ogTitle: t('not-found', 'Síða fannst ekki', 'Page not found'),
        }}
      >
        <main className={cn('relative pb-40 transition-colors duration-1000', color)}>
          <Menu />
          <div className='gutter py-23'>
            <AnimatedText variant='h1' text={t('404-text', '404 - Síða fannst ekki', '404 - Page not found')} />
          </div>
        </main>
        <Footer className='-mt-40 bg-white pt-50 footer-container-clip' />
      </BaseLayout>
    </>
  )
}

export default withLanguageProvider(PageTemplate, 'en')
